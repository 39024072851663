import Link from 'next/link';
import Image from 'next/image';
import format from '@/utils/format';
import { DATETIME } from '@/constants/dateTime';
import { IPostCardProps } from '@/interfaces/card';
import { Layout_Posts_Enum } from '@/generated/graphql';

const VerticalPostCard = ({
  category,
  description,
  id,
  publishedAt,
  slug,
  thumbnail,
  title,
  location,
  layoutId = Layout_Posts_Enum.Content,
}: IPostCardProps) => {
  return (
    <>
      {id ? (
        <Link
          style={{
            animation: 'animate-zoomin 0.5s',
          }}
          href={`/${category?.slug}/${slug}-${id}`}
          className="group flex flex-col"
        >
          <div className="w-full h-[130px] lg:h-[171px] relative">
            <Image
              src={thumbnail || ''}
              alt={title ?? 'tin hay nha dat'}
              fill
              quality={70}
              // className="object-cover"
              style={{ objectFit: 'cover' }}
              sizes="(max-width: 768px) 50vw, (max-width: 1200px) 25vw, 10vw"
            />
          </div>

          <h2 className="mt-[17px] text-base font-medium text-black group-hover:opacity-80 line-clamp-2">
            {title}
          </h2>
          <div className="mt-1 flex flex-col lg:flex-row gap-2 justify-between">
            <p className="text-xs font-light text-black line-clamp-2">
              {layoutId === Layout_Posts_Enum.Gallery ? location : description}
            </p>
            <p className="italic text-xs font-light text-black">
              {format.dateTime(publishedAt || '', DATETIME.SHORT)}
            </p>
          </div>
        </Link>
      ) : (
        <></>
      )}
    </>
  );
};

export default VerticalPostCard;
